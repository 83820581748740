<template>
  <AppLayout>
    <div class="home-container">
      <div class="home-banner">
        <el-carousel :interval="5000" ref="lokk" @change="check" height="38rem">
          <el-carousel-item v-for="item in banner" :key="item.id">
            <h3 v-if="item.bannerType == 1">
              <video
                id="media"
                ref="videoRef"
                :src="item.videoUrl"
                width="1920"
                :fill="true"
                height="600"
                autoplay="autoplay"
                preload="auto"
                controlslist="nodownload noplaybackrate "
                disablePictureInPicture="true"
                @ended="handleended"
                @click="clickPage(item)"
                :muted="true"
                :controls="false"
              ></video>
            </h3>
            <h3 :class="item.clickEvent == 0 ? 'medium' : ''" v-else>
              <img @click="clickPage(item)" :src="item.pictureUrl" alt="" style="width:100%;height:auto;"/>
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="support-page">
        <div class="dd">
          <a
            v-for="(item, index) in caseTitle"
            :key="item.id + index"
            href="javascript:"
            :class="{ active: item.sort === currentTitle }"
            @click="TagListTitle(item)"
            >{{ item.caseTypeName }}</a
          >
        </div>
      </div>
      <div class="border-top"></div>
      <div class="all-case">
        <div class="case-content" v-for="item in allCaseList" :key="item.id">
          <router-link :to="`/solutionsChildren/${item.id}`">
            <div class="case-img">
              <img :src="item.pcPicture" alt="" />
            </div>
            <div class="case-text">
              <p class="case-one">{{ item.title }}</p>
              <p class="case-two">
                {{ item.content }}
              </p>
            </div>
          </router-link>
          <button
            class="case-btn"
            @click="$router.push(`/solutionsChildren/${item.id}`)"
          >
            了解详情
          </button>
        </div>
      </div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import { machineBanner } from "@/api/Home/index.js";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { CaseListTitle, AllPartnerCase } from "../../api/solutions/index.js";
const autoPlay = ref(false);
const lokk = ref(null);
const handleended = () => {
  autoPlay.value = true;
  lokk.value.next();
};
const clickPage = (val) => {
  if (val.clickEvent == 0) {
    if (val.link) {
      if (val.openWay == 1) {
        window.location.href = val.link;
      } else {
        window.open(val.link);
      }
    }
  }
};
const check = (val) => {
  let Media = document.getElementById("media");
  if (val == 0) {
    autoPlay.value = false;
    Media.currentTime = 0;
    Media.play();
  }
};

const currentTitle = ref(0);
const caseTitle = ref([]);
// 列表标题
const getCaseListTitle = async () => {
  try {
    let res = await CaseListTitle();
    if (res.code === 200) {
      caseTitle.value = res.data;
      // console.log(caseTitle.value, "列表标题");
      caseTitle.value.unshift({
        caseTypeName: "全部",
        sort: 0,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
const query = reactive({
  typeName: null,
  title: null,
});
// 点击标题切换
const TagListTitle = (item) => {
  console.log(item.caseTypeName);
  currentTitle.value = item.sort;
  if (item.caseTypeName == "全部") {
    getAllPartnerCase((query.typeName = ""));
  } else {
    getAllPartnerCase((query.typeName = item.caseTypeName));
  }
};
const allCaseList = ref([]);
// 全部列表页面
const getAllPartnerCase = async () => {
  try {
    let res = await AllPartnerCase(query);
    console.log(res, "全部列表页面");
    if (res.code === 200) {
      allCaseList.value = res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
// const id = ref("");
// 了解详情
// const onBtn = (item) => {
//   console.log(item.id, "了解详情页面");
//   id.value = item.id;
//   router.push({});
//   // try {
//   //   let res = await CaseById(id.value);
//   //   console.log(res, "详情页面");
//   // } catch (error) {
//   //   console.log(error);
//   // }
// };
const banner = ref();
const machineCode = ref(7);
onMounted(() => {
  getCaseListTitle();
  getAllPartnerCase();
  machineBanner(machineCode.value).then((res) => {
    const data = res.data;
    const dataPc = [];
    data.forEach((item) => {
      if (item.systemType == 0) {
        dataPc.push(item);
      }
    });
    banner.value = dataPc;
  });
});
</script>
<style scoped lang="less">
video {
  // object-fit: fill;
  background-color: #000;
}
.home-container {
  width: 1920px;
  background: #f2f2f2;
  margin: 0 auto;
  .medium:hover {
    cursor: pointer;
  }
  .support-page {
    width: 1920px;
    display: flex;
    margin-top: 60px;
    .dd {
      display: flex;
      margin: 0 auto;
      > a {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin-right: 60px;
        padding-bottom: 20px;
        &:hover {
          color: #ce1200;
        }
        &.active {
          color: #ce1200;
          border-bottom: 4px solid #ce1200;
          padding-bottom: 20px;
        }
      }
    }
  }
  .border-top {
    width: 1920px;
    height: 1px;
    background: #e1e1e1;
    margin-top: 2px;
    margin-bottom: 40px;
  }
  .all-case {
    width: 1635px;
    // margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    margin-left: 160px;
    .case-content {
      width: 509px;
      height: 500px;
      background: #ffffff;
      border-radius: 10px;
      // margin: 0 auto;
      margin-bottom: 36px;
      margin-right: 36px;
      .hoverShadow ();
      .case-img {
        width: 509px;
        height: 298px;
        img {
          width: 509px;
          height: 298px;
          border-radius: 10px 10px 0px 0px;
        }
      }
      .case-text {
        margin-left: 24px;
        .case-one {
          width: 373px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 30px;
          margin-bottom: 16px;
        }
        .case-two {
          width: 457px;
          height: 41px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 18px;
        }
      }
      .case-btn {
        width: 160px;
        height: 48px;
        border: 1px solid #111111;
        border-radius: 24px;
        background-color: #ffffff;
        margin-left: 24px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        cursor: pointer;
        &:hover {
          color: #ce1200;
          border: 1px solid #ce1200;
        }
      }
    }
  }
}
.el-carousel__item h3 {
  display: flex;
  color: #475669;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
